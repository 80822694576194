import React from 'react';
import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import Container from 'components/Container';
import { Footer } from 'layouts/Main/components';
import Main from 'layouts/Main';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'

const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()


const SelfLearning = () => {
  const theme = useTheme();

  return (
    
    <Box>
        <Main>
   
        <Container maxWidth={0.9} paddingTop={'0 !important'}>

        
        
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box>
          <br />
          <br />
          <Typography fontWeight={700} variant={'h4'} gutterBottom>
          SAFE LEARNING ENVIRONMENT
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ marginY: 4 }} />
      <Grid >
        <Grid item xs={14} md={8}>
          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              Dear Parents
            </Typography >
            <Typography component={'p'} align = 'justify'>
              A positive academic enrolment begins with safe families and safe communities and statistically schools continue to be one of the most secure places for our children. National Public School Yeshwanthpur is committed to the same and ensures a levelled individual and collective social – emotional competence of students and staff to secure the students from emotional toxicities. The school has formulated guidelines for the safety and protection of the students that includes physical, emotional and personal safety, prevention of sexual abuse, awareness of cyber- crime and dealing with emergencies and disasters.

            </Typography>
          </Box>
          <Box marginBottom={1}>
         
            <Typography component={'p'} align = 'justify'>
            We understand the anxiety of parents and as a school, we have a no-tolerance policy to anything that compromises the safety of our students. We thank you for the faith and confidence you have reposed in NPS Yeshwanthpur. We see parents as partners with equal responsibility to ensure that norms are adhered to reassuring the safety of our students
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              {[
                'The school campus is open from 7:30 a.m. to 4:30 p.m only and students should not be on the site before this and all students vacate the school latest by 4:00 p.m',
                'The school campus is under the surveillance through CCTV cameras, which is an added precautionary measure to ensure safety in the school campus.',
                'Fire extinguishers and First Aid Kits have been installed on every floor of each of the school buildings. Further, evacuation drill is practised often to train students in emergency exit procedures.',
                'When a student is found absent, the parents are immediately called up to confirm the absence of the child and parents are asked to send a mail to the school on the same day, stating the reason for the absence.',
                'If a student is unwell during the working hours, the child is helped to relax in the Health Room and parents are called up immediately. NPS Yeshwanthpur is linked with People Tree Hospitals and if the need arises the child is attended by them. A member of the staff normally accompanies the child to the hospital, in case parents take longer time to report.',
                'Parents, you must ensure that we have your contact details for any exigency. It is also very important to inform the school of any changes in your contact information.',
                'If your child is not taking the usual mode of transport back home, it is important to inform the Class Teacher of the change. This information could be communicated through a formal letter addressed to the Principal indicating the changed mode of transport along with the reason. However, no change of bus route is permitted.',
                'Students should be dropped to school and picked up on all working days at the school gate, in case of parent pick-up.',
                'Once they have arrived at school, no student is permitted to leave the school campus without permission. The only entrance into and out of the school is via the school gate which for security reasons is monitored by a CCTV camera and the vigilant security staff.',
                'We have always insisted on parents / guardians / drivers of students studying in class I-XII to wait outside the school premises on all working days, when they come to pick up or drop their ward.',
                'However, students studying in the Montessori and KG can be picked up from the school premises on Mondays to Fridays to ensure a safe exit at the end of the day.',
                'Those persons, who pick up students taking part in co-curricular activities, are not permitted to wait inside the school premises on ANY DAY.',
                'Younger students are not permitted to leave the school with any adult other than the student’s parent or a prearranged guardian with an identity proof and a letter from the parents stating the relationship with the child.',
                'Outside vehicles are not permitted inside the school campus during the school hours.',
                'A favourable gender ratio with more female than male teachers is maintained.',
                'During the school working hours, teachers are present in the classrooms and in the staffrooms, strategically located on every floor of the building. The school is a high traffic zone teeming with adults and students during the school hours with every available room in the building occupied through out the day. At all times during the school day, the students in the building or playfield are under the supervision of the staff and are never left unattended.',
                'We also have teachers on each floor placed in strategic places to supervise the movement of students during the school hours inside the school building.',
                'Separate washrooms have been provided for boys and girls in every floor. The washrooms are opened at 8:00 a.m. and are locked at the end of the school day.',
                'Unused rooms, if any, are always kept locked',
                'In the evening, after the final school bell when students leave for the day, teachers remain in the school building until all the students have exited.',
                'Sensitising the entire teaching staff and support staff is a continuous process. Teachers are trained to attend to adolescent related issues.',
                'Emails and alert messages are sent to parents, sensitising them on various concerns regarding their wards including use of internet and malicious computer games.',
                'Guidance and counselling cell: Highly qualified and experienced counsellors render guidance and counselling services. It provides assistance or intervention for children with respect to learning, emotional and behavioural differences / difficulties observed in school. The children are referred to the school counsellor by the class teachers and a preliminary assessment of the situation is made. The help is offered in terms of counselling, guiding students and parents. The cell also suggests suitable interventional remedies to handle the difficulties. The capability and the outreach of the counselling cell in the school is limited to an initial assessment of the problem.',
                'All maintenance personnel and vendors carry out work after school hours and if entry into the building is imperative, they are accompanied by a school staff member.',
                'All the members of the staff and all the students are issued an ID-card and it is compulsory to wear it with in the school campus or while representing the school outside.',
                'School has various Committees that look into the various aspects of the functioning of the safety measures administered at school. Parents, Teachers and Co-ordinators along with the Principal and Management are the members of the committee.',
                'M/s Sam Tours and Travels, who run the school transport system periodically train and educate the drivers and bus support staff about their expected behaviour and care towards children in the bus and ensure the presence of a lady attendant in the bus whenever there are children in the bus.',
                'All the members of the staff and all the students are issued an ID-card and it is compulsory to wear it in the school campus or while representing the school outside.',
                'School has Parent Teacher Committees at each level to look into the various aspects of the functioning of the safety measures administered at school. Parents, Teachers and Co-ordinators along with the Principal and Management are the members of the committee',
              ].map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} align = 'justify' />
                  </Box>
                </Grid>
              ))}
            </Grid>
            <br/>
            <Typography component={'p'} align='justify'>
            These protective measures have been taken to ensure that our students are in a safe and secure environment while at school. We seek the co-operation of all concerned. The safety of ALL THE STUDENTS entrusted in our care is of paramount significance to us while they are in the school campus.<br/>
            </Typography>
            <Typography component={'p'} align='justify' variant='h5'>
           <br/>PRINCIPAL<br/>
            </Typography>
            <Typography component={'p'} align='justify'>
           <br/>National Public School<br/>
Yeshwanthpur
<br/>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    
        </Container>
        </Main>
    </Box>

  );
};

export default SelfLearning;
